import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import LoginScreen from './login';

const PrivateRoute = () => {
  const isLoggedIn = window.localStorage.getItem("payzojwt");

  // Check if the user is logged in (using a dummy JWT for demonstration)
  if (isLoggedIn === "JWT125421") {
    // If logged in, render the outlet (nested routes)
    return <Outlet />;
  } else {
    // If not logged in, redirect to the login screen
    return <Navigate to="/" replace />;
  }
};

export default PrivateRoute;
