import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LoginScreen from './components/login';
import Dashboard from './components/dashboard';
import PrivateRoute from './components/privateroute';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Route */}
        <Route path="/" element={<LoginScreen />} />
        
        {/* Protected Route */}
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
