import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";


export const getAllCateglory = createAsyncThunk(
    'category',
    async ()=> {
        const response = await fetch('https://vendor.payzopartner.in/payzo-api/getAllbusinesscategory', {
            method : 'GET',
            headers : {
                'content-type' : 'application/json'
            }
        })
        if(response.ok){
            // toast.success("Categories Arrived Successfully");
        }else{
            toast.error("Failed to get Categories");
        }
        return response.json();
    }
)


const CategoriesSlice = createSlice({
    name : 'categories',
    initialState : {
        categories : [],
        loading : false,
        error : null
    },
    reducers : {},
    extraReducers : (builder)=> {
        builder 
        .addCase(getAllCateglory.pending, (state)=> {
            state.loading = true;
            state.error = null
        })
        .addCase(getAllCateglory.fulfilled, (state, action)=> {
            state.loading = false;
            state.categories = action.payload;
        })
        .addCase(getAllCateglory.rejected, (state, error)=> {
            state.loading = false;
            state.error = error.message;
        })
    }
})

export default CategoriesSlice.reducer;