import { configureStore } from "@reduxjs/toolkit";
import userSlicereducer from "../slice/user.slice.js";
import Businessreducer from "./getbusiness.slice.js";
import AnybusinessReducer from "./allbusiness.slice.js";
import AdminLoginReducer from "./adminlogin.slice.js";
import AuthReducer from "./auth.slice.js";
import BusinessByIdreducer from "./businessbyId.slice.js";
import FilterByBusinessTypeReducer from "./filterbytype.slice.js";
import CategoriesReducer from "./category.slice.js";
import getMobileDataReducer from "./mobile.slice.js";
import AddCategoriesReducer from "./addcategoryslice.js";
import getAllStatesReducer from "./getstate.slice.js";
import getAllCitiesReducer from "./getCities.slice.js";
import ImageOffersReducer from "./getimageslice.js";
import DescriptonOfferReducer from "./descriptionoffer.slice.js";

const store = configureStore({
  reducer: {
    users: userSlicereducer,
    getBusiness: Businessreducer,
    anyBusiness: AnybusinessReducer,
    admin: AdminLoginReducer,
    BusinessById: BusinessByIdreducer,
    BusinessType: FilterByBusinessTypeReducer,
    auth: AuthReducer,
    getMobileData: getMobileDataReducer,
    categories: CategoriesReducer,
    addedCategories: AddCategoriesReducer,
    getAllStates: getAllStatesReducer, 
    getAllCities : getAllCitiesReducer,
    ImageOffers: ImageOffersReducer,
    DescriptionOffers: DescriptonOfferReducer
    
   
    
  },
});

export default store;
