import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getDescriptionOffers = createAsyncThunk(
    'getDescriptionOffers',
    async ()=> {
        try {
            const response = await fetch('https://vendor.payzopartner.in/payzo-api/getAllDescriptionOffers')
            if(!response.ok){
                throw new Error("Error fetching Description Offers")
            }else{
                return response.json();
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
)

const DescriptionOfferSlice = createSlice({
    name : 'DescriptionOffers',
    initialState : {
        loading : false,
        error : null,
        DescriptionOffers : []
    },
    reducers : {},
    extraReducers :(builder)=> {
        builder
        .addCase(getDescriptionOffers.pending, (state)=> {
            state.loading = true;
            state.error = null;
        })
        .addCase(getDescriptionOffers.fulfilled, (state, action)=> {
            state.loading = false;
            state.error = null;
            state.DescriptionOffers = action.payload;
        })
        .addCase(getDescriptionOffers.rejected, (state, action)=> {
            state.loading = false;
            state.error = action.error.message;
            state.DescriptionOffers = [];
        })
    }
})

export default DescriptionOfferSlice.reducer;