import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// Define async thunk for getting all states
export const getAllStates = createAsyncThunk(
  'states/getAllStates', // Action name
  async () => {
    try {
      const response = await axios.get('https://vendor.payzopartner.in/payzo-api/getStates', {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      // Check if the response is successful
      if (response.data.success) {
        return response.data.data; // Return the states data
      } else {
        toast.error("Error fetching States"); // Show an error toast if response.success is false
        return []; // Return an empty array as a fallback
      }
    } catch (error) {
      toast.error("Something went wrong!"); // Show toast on error
      console.error(error); // Log error for debugging
      return []; // Return empty array in case of error
    }
  }
);



// Create slice for getting all states
const getStateSlice = createSlice({
  name: 'getAllStates',
  initialState: {
    StatesData: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllStates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllStates.fulfilled, (state, action) => {
        state.loading = false;
        state.StatesData = action.payload;
      })
      .addCase(getAllStates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        toast.error(state.error);
      });
  }
});

export default getStateSlice.reducer;
