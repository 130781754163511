import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// Creating the async thunk
export const AddCategories = createAsyncThunk(
    'categories/add', // This action name should be descriptive and not just 'categories'
    async ({AddedCategories}) => {
        try {
            // Making the POST request using axios
            const response = await axios.post('https://vendor.payzopartner.in/payzo-api/addCategories', {
                'name': AddedCategories,
                'status': 1
            }, {
                headers: {
                    'content-type': 'application/json'
                }
            });
            if (response.data.success === true) {
                toast.success("Category Added Successfully")
            }else{
             toast.error("Category Added Failed");   
            }
            return response.data;    
        } catch (error) {
            console.log(error);
            throw new Error(error.message);
        }
    }
);
    
// Creating the slice
const CategoriesSlice = createSlice({
    name: 'addedCategories',
    initialState: {
        loading: false,
        CategoriesAdded: [],
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(AddCategories.pending, (state) => {
                state.loading = true;
                state.error = null; // Ensure no error is present when loading
            })
            .addCase(AddCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.CategoriesAdded = action.payload; // Update with the returned payload
                state.error = null;
            })
            .addCase(AddCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; // Properly capture the error message
            });
    }
});

export default CategoriesSlice.reducer;
