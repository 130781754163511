import React, { useState, useEffect } from "react";
import "../styles/dashboard.scss";
import { IoMdNotifications } from "react-icons/io";
import { TiMessages } from "react-icons/ti";
import { FaUserCog } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Pagination from 'react-bootstrap/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getAllusersData } from "../redux/slice/user.slice";
import { getBusinessByUserId } from "../redux/slice/getbusiness.slice";
import { FilterByType } from "../redux/slice/filterbytype.slice";
import { getAllCateglory } from "../redux/slice/category.slice";
import { IoMdHeadset } from "react-icons/io";
import { IoWalletOutline } from "react-icons/io5";
import { IoMdArrowBack } from "react-icons/io";
import { FaRegCircle } from "react-icons/fa";
import { RiRectangleLine } from "react-icons/ri";
import { getMobileData } from "../redux/slice/mobile.slice";
import { AddCategories } from "../redux/slice/addcategoryslice";
import { getAllStates } from "../redux/slice/getstate.slice";
import { getAllCities } from "../redux/slice/getCities.slice";
import { MdDelete } from "react-icons/md";
import { getImageOffers } from "../redux/slice/getimageslice";
import { getDescriptionOffers } from "../redux/slice/descriptionoffer.slice";
import { toast, ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Modal from "./modal";
import FullScreenCard from "./fullscreen";
import * as XLSX from 'xlsx';




function Dashboard() {
  const navigate = useNavigate();
  const [activeContent, setActiveContent] = useState('content1');
  const [currentPage, setCurrentPage] = useState(1);
  const [InputUserId, setInputUserId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [AllBusinessData, setAllBusinessData] = useState([]);
  const [BusinessId, setBusinessId] = useState("");
  const [BusinessByIdData, setBusinessByIdData] = useState([]);
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [cityUpload, setCityupload] = useState("");
  const [mentionBusinessType, setmentionBusinessType] = useState("");
 const [CategoryId, setCategoryId] = useState("");
 const [AllFilterData, setAllFilterData] = useState([])
const [BusinessType, setBusinessType] = useState("")
const [MobilescreenTitle, setMobileScreenTitle] = useState("");
const [MobileScreenImage, setMobileScreenImage] = useState(null);
const [showModal, setShowModal] = useState(false); 
const [imageSrc, setImageSrc] = useState(''); 
const [BusinessIdforVerification, setBusinessIdforVerification] = useState("");
const [AdminshowDocument, setAdminshowDocument] = useState([]);
const [UsersData, setUsersData] = useState([]);
const [AddedCategories, setAddedCategories] = useState("");
const [OffersData, setOffersData] = useState([]);
const [fullScreenImage, setFullScreenImage] = useState(null);




const { loading, data:userData,} = useSelector((state) => state.users);
const { data: businessData } = useSelector((state) => state.getBusiness);
const { BusinessTypeData, error } = useSelector((state) => state.BusinessType);
const { categories} = useSelector((state)=>  state.categories)
const {  MobileData } = useSelector((state) => state.getMobileData);
const { StatesData } = useSelector((state) => state.getAllStates);
const { getAllCitiesData } = useSelector((state) => state.getAllCities);
const {  ImageOffers } = useSelector((state) => state.ImageOffers);
const {DescriptionOffers} = useSelector((state)=> state.DescriptionOffers);
// const BusinessIdData = useSelector((state) => state.BusinessById?.BusinessById || []);



const dispatch = useDispatch();
const usersPerPage = 10;
const usersData = Array.isArray(userData.data) ? userData.data : [];
const BusinessData = Array.isArray(businessData.businesses) ? businessData.businesses : [];
const BusinessByTypeDatas = Array.isArray(BusinessTypeData.data) ? BusinessTypeData.data : [];
const CategoriesFetch = Array.isArray(categories.data) ? categories.data : [];
const getMobileDataFetch = Array.isArray(MobileData.mobile) ? MobileData.mobile : [];
const finalImageOffers = Array.isArray(ImageOffers.offers) ? ImageOffers.offers : [];
const finalDescriptionOffers = Array.isArray(DescriptionOffers.offers) ? DescriptionOffers.offers : [];



// const getBusinessByBusinessId = Array.isArray(BusinessIdData?.business) ?  BusinessIdData.business : [];

// Pagination logic
const totalPages = Math.ceil(usersData.length / usersPerPage);
const indexOfLastUser = currentPage * usersPerPage;
const indexOfFirstUser = indexOfLastUser - usersPerPage;
const currentUsers = usersData.slice(indexOfFirstUser, indexOfLastUser);
const getBusinessAll = BusinessData.slice(indexOfFirstUser, indexOfLastUser); 
const getBusinessByBusinessType = BusinessByTypeDatas.slice(indexOfFirstUser, indexOfLastUser);
// const getBusinessByBusinessIdData = getBusinessByBusinessId.slice(indexOfFirstUser, indexOfLastUser);

// Function to handle opening the image in full-screen mode
const handleOpenFullScreen = (imageUrl) => {
    setFullScreenImage(imageUrl);
};

// Function to handle closing the full-screen image modal
const handleCloseFullScreen = () => {
    setFullScreenImage(null);
};




useEffect(()=> {
  const fetchAllOffersForApproval  = async (e)=> {
    try {
      const response = await axios.get('https://vendor.payzopartner.in/payzo-api/Allofferslive')
      if(response.data.success){
        setOffersData(response.data.allOffers)
      }else{
        toast.error("Failed to fetch Offers")
      }
    } catch (error) {
      console.log(error);
    }
  }
  fetchAllOffersForApproval();
}, [])







const handleApproveOffer = async (id) => {
  try {
    const approvalResponse = await axios.post('https://vendor.payzopartner.in/payzo-api/updateOfferById', {
      id: id,
      approval_status: "approved",  
      remark: "no remarks"             
    });

    if (approvalResponse.data.success === true) {
      toast.success("Offer approved successfully!");
    } else {
      toast.error("Failed to approve the offer. Please try again.");
    }
  } catch (error) {
    console.log(error);
    toast.error("An error occurred while approving the offer.");
  }
};



const handleRejectOffer = async (id) => {

  const promptadmin = prompt("Please mention why you rejecting Offer");

  try {
    const approvalResponse = await axios.post('https://vendor.payzopartner.in/payzo-api/updateOfferById', {
      id: id,
      approval_status: "rejected",  
      remark: promptadmin             
    });

    if (approvalResponse.data.success === true) {
      toast.success("Offer Rejected successfully!");
    } else {
      toast.error("Failed to Reject the offer. Please try again.");
    }
  } catch (error) {
    toast.error("An error occurred while rejecting the offer.");
  }
};


const handleDeleteOffer = async (id) => {
  try {
  
    const deleteResponse = await axios.delete('https://vendor.payzopartner.in/payzo-api/deleteOfferById', {
      data:{id:id}
    });

    if (deleteResponse.data.success === true) {
      toast.success("Offer Deleted Successfully");
    } else {
      toast.error("Offer Deletion Failed");
    }
  } catch (error) {
    toast.error("An error occurred while deleting the offer");
  }
};









const exportToExcel = () => {
  const ws = XLSX.utils.json_to_sheet(UsersData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Users');
  XLSX.writeFile(wb, 'users_data.xlsx');
};

const handleConvertDataintoXL = async(e) => {
 e.preventDefault();
 try {
  const response = await axios.get('https://api.payzopartner.com/payzo-api/users') 
  if(Array.isArray(response.data.data)){
    toast.success("XL SHEET Ready for Download");
    setUsersData(response.data.data);
  }
 } catch (error) {
  console.error('Error fetching data:', error)
 }
  
};



const handleAddCategoy = (e)=> {
  try {
    dispatch(AddCategories({AddedCategories}))
    setTimeout(()=> {
   window.location.reload();
    }, 3000)
  } catch (error) {
    console.log(error);
  }
}


const handleLogout = (e)=> {
  e.preventDefault();
  window.localStorage.removeItem("payzojwt");
  navigate("/");
}





// setTimeout(()=> {
// window.localStorage.removeItem("payzojwt");
// }, 180000)

useEffect(()=> {
  dispatch(getDescriptionOffers());
}, [dispatch])

useEffect(()=> {
  dispatch(getImageOffers());

}, [dispatch])

useEffect(()=> {
  dispatch(getAllCities())
}, [dispatch])


useEffect(()=> {
 dispatch(getAllStates());
}, [dispatch])

  
  const handleSubmitAddMobileScreen = async (e) => {
    e.preventDefault();  // Prevent default form submission behavior
  
    const formData = new FormData();
    formData.append("mobile_title", MobilescreenTitle);  // Appending title
    formData.append("mobile_image", MobileScreenImage);  // Appending the image file
  
    try {
      const response = await fetch('https://vendor.payzopartner.in/payzo-api/addScreens', {
        method: 'POST',
        body: formData,  // Send formData with the file and data
      });
  
      // Handle response status
      if (response.ok) {
        toast.success("Mobile Data Added Successfully");
      } else {
        toast.error("Failed to add Mobile Data");
      }
    } catch (error) {
      console.error("Error:", error);  // Log error details
      toast.error("An unexpected error occurred.");
    }
  };
  


  
  


  




  useEffect(()=> {
    dispatch(getMobileData());
  }, [dispatch])
  
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
   
  };
  
  // Handle page click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  useEffect(()=>{
   dispatch(getAllusersData());
  }, [dispatch])

  const handlegetBusinessByBusinessType = (e) => {
    e.preventDefault();
    if (mentionBusinessType.trim()) {  // Ensure a non-empty search
      dispatch(FilterByType(mentionBusinessType));  // Dispatch filter action with the business type
    }
  };

useEffect(()=> {
 dispatch(getAllCateglory());
}, [dispatch])


  useEffect(()=>{
    const getAllStates = async () => {
      try {
        const StateResponse = await axios.get('https://vendor.payzopartner.in/payzo-api/getStates');
        if (StateResponse.data.success === true) {
          setState(StateResponse.data.data);  // Make sure this matches the actual data structure
        } else {
          toast.error("Failed to fetch States");
        }
      } catch (error) {
        console.log(error);
        toast.error("Error fetching states");
      }
    }
    getAllStates();
  }, [])

  useEffect(() => {
    if (selectedState) {
      axios
        .post("https://vendor.payzopartner.in/payzo-api/getCities", {
          state_id: selectedState
        })
        .then((res) => {
          console.log("API response for cities:", res);
          if (Array.isArray(res.data.data)) {
            setCities(res.data.data);
          } else {
            console.error("Expected an array but got:", res.data.data);
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the cities!", error);
        });
    }
  }, [selectedState]);

  


  const generatePaginationItems = () => {
    const pages = [];

    // First page, prev, next, last
    if (currentPage > 1) {
      pages.push(<Pagination.First onClick={() => handlePageClick(1)} />);
      pages.push(<Pagination.Prev onClick={() => handlePageClick(currentPage - 1)} />);
    }

    // Middle pagination items
    const rangeStart = Math.max(1, currentPage - 2);
    const rangeEnd = Math.min(totalPages, currentPage + 2);

    for (let i = rangeStart; i <= rangeEnd; i++) {
      pages.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageClick(i)}>
          {i}
        </Pagination.Item>
      );
    }

    // Ellipses and last page
    if (rangeEnd < totalPages) {
      pages.push(<Pagination.Ellipsis />);
      pages.push(
        <Pagination.Item key={totalPages} onClick={() => handlePageClick(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    // Next page, last
    if (currentPage < totalPages) {
      pages.push(<Pagination.Next onClick={() => handlePageClick(currentPage + 1)} />);
      pages.push(<Pagination.Last onClick={() => handlePageClick(totalPages)} />);
    }

    return pages;
  };

  const handleSearchBusiness = (e) => {
    e.preventDefault();
    if (InputUserId) {
      dispatch(getBusinessByUserId(InputUserId));
    } else {
      toast.error("No user Found");
    }
  };



  const fetchBusinessByBusinessId = async () => {
    try {
     
      const response = await axios.get(`https://vendor.payzopartner.in/payzo-api/getBusinessById/${BusinessId}`);
      
      if (response.data.success &&  response.data.business) {
        setBusinessByIdData([response.data.business]);
      } else {
        toast.error("Error: Invalid business data received.");
      }
      }
      
    catch (error) {
      toast.error("No Business Found")
    }
  };
  

      useEffect(()=>{
        const fetchAllBusinessData = async () => {
          try {
            const response = await axios.get('https://vendor.payzopartner.in/payzo-api/getAllBusiness');
            
            if (response.data.success && Array.isArray(response.data.businesses)) {
               
              setAllBusinessData(response.data.businesses); 
            } else {
              toast.error("Error: Invalid business data received.");
            }
          } catch (error) {
            console.error("Error fetching business data:", error);
            toast.error("Error fetching business data.");
          
      };
      }
      fetchAllBusinessData();
      },[]);



      const handleuploadCity = async(e)=> {
        e.preventDefault();
        try {
          const response = await axios.post('https://vendor.payzopartner.in/payzo-api/addCities', {
            'state_id' : selectedState,
            'name' : cityUpload
          })
          if(response.success === true){
            toast.error("Failed to upload City");
        } else {
          
          toast.success("City Uploaded Successfully ");

          }
        } catch (error) {
          console.log(error);
        }
      }



      const AllFilterSearchApi = async (e) => {
        e.preventDefault();
      
        try {
          const response = await axios.post('https://vendor.payzopartner.in/payzo-api/getAllFilter', {
            'state_id': "",
            'city_id': "",
            'business_type': BusinessType,
            'category_id': CategoryId
          });
      
          // Check if the response status is successful and data exists
          if (response.status === 200 && response.data.success) {
            toast.success("Data fetched successfully!");
          
            if (Array.isArray(response.data.data) && response.data.data.length > 0) {
              setAllFilterData(response.data.data); // Set the data from response
            } else {
              toast.error("No businesses found.");
            }
          } else {
            // If API response does not have 'success' as true or status code isn't 200
            toast.error(response.data.message || "Failed to fetch data.");
          }
        } catch (error) {

          console.error("Error during API call:", error);
          toast.error("Something went wrong, please try again.");
        }
      };


      const handleDeleteBusiness = async (business_id) => {
        const userConfirmed = window.confirm("Are you sure you want to delete this business?");
        
        if (!userConfirmed) return; 
        
        try {
          const response = await axios.delete(
            'https://vendor.payzopartner.in/payzo-api/deleteBusiness',
            { data: { business_id } }
          );
          
          if (response.data.success) {
            toast.success("Business Deleted Successfully");
            
          } else {
            toast.error("Failed to Delete Business");
          }
          
        } catch (error) {
          toast.error("Please try again later.");
          console.error('Error deleting business:', error);  // Improved error logging
        }
      };


  return (
    <div>
      <div className="Dashboard-main-container">
        <ToastContainer />
        <div className="dashboard-navbar">
          <img src="https://www.payzopartner.com/static/media/logo.2e8f0a49aa17317034bd.png" alt="Logo" width={170} height={100} />
          <div style={{ width: "30rem", height: "5rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "0.2rem" }}>
            {/* <input type="search" placeholder="search username" className="universal-search" />
            <button className="search-button">Search</button> */}
            
           
            <h1 style={{color:'white', fontWeight:'700', }}>Welcome Admin </h1>
           
          </div>
          <div className="icon-section">
            <p><IoMdNotifications /></p>
            <h6><TiMessages /></h6>
            <h6><FaUserCog /></h6>
            <h6 style={{ fontSize: '18px', cursor:'pointer' }} onClick={handleLogout}>Logout</h6>
          </div>
        </div>

        <div className="side-shower">
          <div className="body-content">
            <div style={{ display: 'flex' }}>
              {/* Sidebar */}
              <div style={{
                width: '200px', backgroundColor: 'black', padding: '10px', height: '41rem', gap: '0.5rem',
                display: 'flex', flexDirection: 'column'
              }}>
                <DropdownButton id="dropdown-basic-button" title="Users">
                  <Dropdown.Item onClick={() => setActiveContent('content1')}>Search Users</Dropdown.Item>
                  <Dropdown.Item onClick={()=> setActiveContent('content12')}>Download to XL</Dropdown.Item>
                </DropdownButton>

                <DropdownButton id="dropdown-basic-button" title="Business">
                  <Dropdown.Item onClick={() => setActiveContent('content3')}>Availble Business</Dropdown.Item>
                  <Dropdown.Item onClick={() => setActiveContent('content2')}>Business By User Id</Dropdown.Item>
                  <Dropdown.Item onClick={()=> setActiveContent('content5')}>Business By Business Id</Dropdown.Item>
                </DropdownButton>

                <DropdownButton id="dropdown-basic-button" title="Filter Business">
                  <Dropdown.Item onClick={() => setActiveContent('content6')}>Filter By Business Type</Dropdown.Item>
                  <Dropdown.Item onClick={()=> setActiveContent('content7')}>All Filter Search</Dropdown.Item>
              
                </DropdownButton>

                <DropdownButton id="dropdown-basic-button" title="Offers Control">
                  <Dropdown.Item onClick={()=> setActiveContent('content14')}>Approve Offers</Dropdown.Item>
                  <Dropdown.Item onClick={() => setActiveContent('content15')}>Image Offer</Dropdown.Item>
                  <Dropdown.Item onClick={() => setActiveContent('content16')}>Description Offer</Dropdown.Item>
                </DropdownButton>

             

                <DropdownButton id="dropdown-basic-button" title="State Update">
                  <Dropdown.Item onClick={() => setActiveContent('content4')}>State Update</Dropdown.Item>
              
                </DropdownButton>

                <DropdownButton id="dropdown-basic-button" title="Payzo Mobile Control">
                  <Dropdown.Item onClick={() => setActiveContent('content8')}>Mobile Control</Dropdown.Item>
                  <Dropdown.Item onClick={()=> setActiveContent('content9')}>Add Mobile Data</Dropdown.Item>
                </DropdownButton>
                <DropdownButton id="dropdown-basic-button" title="Upload Categories">
                  <Dropdown.Item onClick={()=> setActiveContent('content13')}>Add Category</Dropdown.Item>
                </DropdownButton>
              </div>

              {/* Right Content */}
              <div style={{ marginLeft: '10px', padding: '10px' }}>
                {/* Content1 */}
                {activeContent === 'content1' && (
                  <div className="content1-style">
                    <div className="content1-header">
                      {/* <input className="user-input" type="search" placeholder="Please search by userId" />
                      <button className="content1-searcher">Search</button> */}
                    </div>
                    <div className="content1-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>User ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Promo Code</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          {currentUsers.map((user, index) => (
                            <tr key={index}>
                              <td>{user.user_id}</td>
                              <td>{user.full_name}</td>
                              <td>{user.email}</td>
                              <td>{user.phone_number}</td>
                              <td>{user.promo_code}</td>
                              
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {/* Content2 */}
{activeContent === 'content2' && (
                  <div className="content2-container">
                    <div className="content2-container-navbar">
                      <input className="search2" type="search" placeholder="Please search UserId"
                        value={InputUserId}
                        onChange={(e) => setInputUserId(e.target.value)} />
                      <button onClick={handleSearchBusiness} className="search2-button">Search User</button>
                    </div>
                    <div className="bigcard-box">
                      {getBusinessAll.map((items, index) => (
                        <div key={index} className="sideuser-content">
                          <img className="mega-pixel" src={items.profile_image} alt="" />
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>Business Name :-</strong>
                            <small>{items.business_name}</small>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>Vendor Name :-</strong>
                            <small>{items.vendor_name}</small>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>Email :-</strong>
                            <small>{items.email}</small>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>Phone Number :-</strong>
                            <small>{items.phone_number}</small>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>Store Category :-</strong>
                            <small>{items.category_name}</small>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>City :-</strong>
                            <small>{items.city_name}</small>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '0.5rem' }}>
                            <strong>Address :-</strong>
                            <small style={{ fontSize: '11px' }}>{items.address}</small>
                          </div>
                          <div className="aproval">
                            <button className="btn-1">Approval</button>
                            <button onClick={openModal} className="btn-1">Reject</button>


                       <Modal isOpen={isModalOpen} closeModal={closeModal}>
                         <h2>Dear Admin please mention Why you Reject Business</h2>
                          <input className="modal-input" type="text" placeholder="Please mention your Review" />
                           <button onClick={closeModal}>Submit Review</button>
                             </Modal>
                            
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
)}

               {/* Content 3 */}
{activeContent === 'content3' && (
  <div className="content3-style">
    <div className="main-content3">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="table-responsive" style={{ maxHeight: '35rem', overflowX: 'auto', fontSize: "12px" }}>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Business Id</th>
                <th>Vendor Id</th>
                <th>Business Name</th>
                <th>Business Type</th>
                <th>Category Name</th>
                <th>Phone Number</th>
                <th>City Name</th>
                <th>State Name</th>
                <th>Address</th>
                <th>Email</th>
                <th>Pincode</th>
                <th>Google Map Link</th>
                <th>Website Link</th>
                <th>Promo Code</th>
                <th>Created at</th>
                <th>Status</th>
                <th>Remark</th>
                <th>Kyc Document</th>
                <th>Document Name</th>
                <th>Document Id</th>
                <th>Full Screen</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {AllBusinessData.length > 0 ? (
                AllBusinessData.map((item, index) => (
                  <tr key={index}>
                    <td>{item.business_id}</td>
                    <td>{item.vendor_id}</td>
                    <td>{item.business_name}</td>
                    <td>{item.business_type}</td>
                    <td>{item.category_name}</td>
                    <td>{item.phone_number}</td>
                    <td>{item.city_name}</td>
                    <td>{item.state_name}</td>
                    <td>{item.address}</td>
                    <td>{item.email}</td>
                    <td>{item.pincode}</td>
                    <td>{item.google_map_link}</td>
                    <td>{item.website_link}</td>
                    <td>{item.promo_code}</td>
                    <td>{item.created_at}</td>
                    <td>{item.approval_status}</td>
                    <td>{item.remark}</td>

                    <td>
                      <img src={item.kyc_doc_image} alt="KYC" style={{ width: "5rem", height: "5rem" }} />
                    </td>
                    <td>{item.documentTitle}</td>
                    <td>{item.documentId}</td>

                    <td><FullScreenCard businessId={item.business_id} /></td>
                    {/* Fixed the onClick handler to pass the function reference */}
                    <td>
                      <button 
                       onClick={() => handleDeleteBusiness(item.business_id)}> 
                      
                        <MdDelete />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" style={{ textAlign: 'center' }}>No business data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  </div>
)}



{/* Content 4 - Business Approval */}
{activeContent === 'content4' && (
  <div className="content4-style">
    <div className="content4-navbar">
      <h1 className="state-title">State and Cities Update</h1>
    </div>

    {/* Map over fetched states and create a dropdown for each */}
    
      <div className="user-details-container" >
        
         <select
         value={selectedState}
         onChange={(e) => setSelectedState(e.target.value)}
         className="input-businesss"
       >
         <option value="" >
           Select a state
         </option>
         {state.map((item, index) => (
           <option key={index} value={item.id}>
             {item.name}
           </option>
         ))}
       </select>
       <div style={{width:'100%', display:'flex', flexDirection:'row', justifyContent:'center', gap:'3rem'}}>
         <input type="search" value={cityUpload} onChange={(e)=> setCityupload(e.target.value)} placeholder="please enter City Name" />
       <button onClick={handleuploadCity}>Upload City</button>
        </div>
          </div>
            </div>

)}




{/* content 5 */}
{activeContent === 'content5' && (
  <div className="content5-container">
    <div className="content5-navbar">
      
      {/* Modal component */}
      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <h2>Dear Admin, please mention why you reject the business</h2>
        <input className="modal-input" type="text" placeholder="Please mention your review" />
        <button onClick={closeModal}>Submit Review</button>
      </Modal>
    </div>

    {/* Body Section */}
    <div className="content5-bodysection">
      {/* Search Section */}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '0.5rem' }}>
        <input type="text" value={BusinessId}
         onChange={(e) => setBusinessId(e.target.value)}
        className="content5-input" placeholder="Please search Business Id" />
        <button onClick={fetchBusinessByBusinessId}>Search</button>
      </div>

      {/* Rendering business data */}
      {BusinessByIdData.map((item, index) => {
        return (
          <div className="content5-card" key={index}>
            <div className="businessone">
              <img
                className="content5-sideimage"
                src={item.profile_image}
                alt="business"
              />
              <h6 style={{color:"red"}}>Business Name : {item.business_name}</h6>
              <h6>Vendor Name : {item.vendor_name}</h6>
              <h6>Business Type : {item.business_type}</h6>
            </div>
            <div className="businesstwo">
              <h6>Store timing : {item.store_timing}</h6>
              <h6>Phone Number : 6281073571</h6>
                <h6>Address</h6>
              <marquee>
                <h6>{item.address}</h6>
              </marquee>
              <h6>State :{item.state_name}</h6>
              <h6>City : {item.city_name}</h6>
              <h6>Email : {item.email}</h6>
              <h6>Pincode : {item.pincode}</h6>
              <a href={item.google_map_link}>Google Map Link :  {item.google_map_link}</a>
              <a href={item.website_link}>Website Link : {item.website_link}  notprovided</a>
              <h6>Approval status : {item.approval_status}</h6>
              <h5>Remark : {item.remark}</h5>
            </div>
          </div>
        );
      })}
    </div>
  </div>
)}




      {/* content 6 */}
{activeContent === 'content6' && (
        <div className="content6-container" style={{backgroundColor:'white', borderRadius:'1rem'}}>
          <div className="content6-navbar" style={{backgroundColor:'white', color:'black'}}>
            <h2>FILTERED BY BUSINESS TYPE</h2>
            <input
              type="text"
              className="type-searcher-input"
              placeholder="Enter Business Type"
              value={mentionBusinessType}  // Bind input value to state
              onChange={(e)=> setmentionBusinessType(e.target.value)} // Update state on input change
            />
            <button onClick={handlegetBusinessByBusinessType}>Search Business Type</button>
          </div>
          <div className="content6-card-container" style={{backgroundColor:'white', gap:'1rem'}}>
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
               BusinessTypeData?.data?.map((item, index) => (
                <div className="filter-card" key={index} style={{color:'black'}}>
                  <img className="filtercard-image" src={item.profile_image || ""} alt={item.business_name} />
                  <h4>{item.business_name}</h4>
                  <h6>Business Type : {item.business_type}</h6>
                  <h6>Phone Number : {item.phone_number}</h6>
                  <h6>Approval Status : {item.approval_status}</h6>
                </div>
              ))
            )}
          </div>
        </div>
)}
  


       {/* Content 7  */}
{activeContent === 'content7' && (
        <div>
          <div className="content7-container">
            <div className="content7-navpanel">
              <h6>Select Any thing I Will Provide the Business</h6>
              <div className="selector-space">

              <select value={BusinessType} onChange={(e) => setBusinessType(e.target.value)}>
      <option value="" disabled>Select Business Type</option>
      <option value="online">Online</option>
      <option value="offline">Offline</option>
    </select>

    <select value={selectedState} onChange={(e)=> setSelectedState(e.target.value)}>
        <option value="">Select State</option>
        {StatesData.map((state) => (
          <option key={state.id} value={state.id}>
            {state.name}
          </option>
        ))}
      </select>
              
{/* 
      <select>
  <option>Select Cities</option>
  {getAllCitiesData.length > 0 ? (
    getAllCitiesData.map((city) => (
      <option key={city.id} value={city.id}>
        {city.name}
      </option>
    ))
  ) : (
    <option>No cities available</option>
  )}
</select> */}


              <select
      value={CategoryId}
      onChange={(e) => setCategoryId(e.target.value)}
    >
      <option value="" disabled>Select Category</option>
    
      {CategoriesFetch.length > 0 ? (
        CategoriesFetch.map((item, index) => (
          <option key={index} value={item.id}>
            {item.name}
          </option>
        ))
      ) : (
        <option value="" disabled>No Categories Available</option>
      )}
    </select>
                 <button onClick={AllFilterSearchApi}>Search</button>
                </div>
              </div>
              <div className="Body-panel">
 
    {AllFilterData.map((item, index) => (
      <div className="markcard" key={index}>
        <img src={item.profile_image} alt="Profile" className="markcard-image" />
        <h6>{item.business_name}</h6>
        <h6>{item.approval_status}</h6>
        <h6>{item.vendor_name}</h6>
        <h6>{item.phone_number}</h6>
      </div>
    ))}
</div>

       </div>
          </div>
)}

    

{/* Content 8 */}
{activeContent === 'content8' && (
  <div>
    <div className="activecontent8-container">
      <div className="mobile-screen">
        
        {/* Mobile Navbar */}
        <div className="mobile-navbar">
          <img 
            className="zo-logo" 
            src="https://play-lh.googleusercontent.com/nu5ywjH1_TogFUjYjay4PTbbsbfZPR5bgqy1ebVbI5Z1YstxPpfdxQ1PZDKRa38=w240-h480-rw" 
            alt="Logo" 
          />
          <div>
            <IoMdHeadset />
            <IoWalletOutline />
          </div>
        </div>
        
        {/* Mobile Body */}
        {getMobileDataFetch.map((item) => (
  <div className="mobile-body" >  {/* Assuming each item has a unique 'id' */}
    <div className="card-group" key={item.id}>
      <img src={item.mobile_image} alt={item.mobile_title || 'Mobile Image'} />  {/* Better alt text */}
      <h6>{item.mobile_title}</h6>
    </div>
  </div>
))}


      {/* Mobile Footer */}
      <div className="mobile-footer">
        <IoMdArrowBack />
        <FaRegCircle />
        <RiRectangleLine />
      </div>
      </div>


    </div>
  </div>
)}

{activeContent === 'content9' && (
        <div className="content9-container">
          <div className="content9-form">
            <h6>+ Mobile Screen Data</h6>
            <input
              value={MobilescreenTitle}
              onChange={(e) => setMobileScreenTitle(e.target.value)} 
              className="content9-input"
              type="text"
              placeholder="Enter title for Mobile Screen"
            />
            <div className="image-upload-container">
              <input
                type="file"
                onChange={(e) => setMobileScreenImage(e.target.files[0])}  // Set the first file selected
                placeholder="Please upload Image"
              />
            </div>
            <button onClick={handleSubmitAddMobileScreen}>Upload</button>
          </div>
        </div>
)}
    

{/* {content 12} */}
  <div>
      {/* Check for active content */}
      {activeContent === 'content12' && (
        <div className="content12-container">
          <div className="content12-navbar">
            <h2 style={{ color: 'lightblue' }}>Hello Admin, Please Download Users Data</h2>
          </div>
          
          <div>
            {/* Button to trigger data conversion */}
            <button onClick={handleConvertDataintoXL}>Click to Generate the Data into XLSheet</button>
            {/* Button to directly download the Excel sheet */}
            <button onClick={exportToExcel}>Download XL SHEET</button>
          </div>

        </div>
      )}
  </div>

{/* {content 13} */}
{activeContent=== 'content13' && (
    <div className="content13-container">
    <div className="content13-navbar">
    <h1 style={{color:"lightgray"}}>Please Upload Categories Here</h1>
      </div>

      <div className="content13-body">
        <input
         className="category-input"
          type="text"
           placeholder="Please Enter Category Name"
           value={AddedCategories}
           onChange={(e)=> setAddedCategories(e.target.value)}
           />
        <button onClick={handleAddCategoy}>Upload Category</button>

        <select>
          <option>show avaliable categories</option>
          {CategoriesFetch.map((item, index)=> (
            <option key={index} value={item.id}>{item.name}</option>
          ))}
        </select>
        </div>
     </div>
 )}

{/* Content 14 For Offers Approval */}
{activeContent === 'content14' && (
  <div>
                    <div className="content-14-container">
                        <div className="main-content3">
                            {loading ? (
                                <p>Loading...</p>
                            ) : (
                                <div
                                    className="table-responsive"
                                    style={{
                                        maxHeight: '35rem',
                                        overflowX: 'auto',
                                        fontSize: '12px',
                                    }}
                                >
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>Offer Id</th>
                                                <th>Vendor Id</th>
                                                <th>Business Id</th>
                                                <th>Offer Title</th>
                                                <th>Description</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                                <th>Offer Status</th>
                                                <th>Approval Status</th>
                                                <th>Remarks</th>
                                                <th>Offer Type</th>
                                                <th>Remark</th>
                                                <th>Approve</th>
                                                <th>Reject</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {OffersData.length > 0 ? (
                                                OffersData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.id}</td>
                                                        <td>{item.vendor_id}</td>
                                                        <td>{item.business_id}</td>
                                                        <td>{item.offer_title}</td>
                                                        <td>{item.offer_description}</td>
                                                        <td>{item.start_date}</td>
                                                        <td>{item.end_date}</td>
                                                        <td>{item.start_time}</td>
                                                        <td>{item.end_time}</td>
                                                        <td>{item.offer_status}</td>
                                                        <td>{item.approval_status}</td>
                                                        <td>{item.remark}</td>
                                                        <td>{item.offer_type}</td>

                                                        {/* Small Image with Clickable Full-Screen Feature */}
                                                        <td>
                                                       
                                                            <img 
                                                                style={{
                                                                    width: '5rem',
                                                                    height: '5rem',
                                                                    cursor: 'pointer',
                                                                  
                                                                }}
                                                                src={item.offer_image}
                                                                alt="Offer Image"
                                                                onClick={() =>
                                                                    handleOpenFullScreen(
                                                                        item.offer_image
                                                                    )
                                                                }
                                                            />
                                                        </td>

                                                        {/* Approve / Reject Buttons */}
                                                        <td>
                                                            <button style={{backgroundColor:'green'}} onClick={() => handleApproveOffer(item.id)}>
                                                                Approve
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button style={{backgroundColor:'red'}} onClick={() => handleRejectOffer(item.id)}>
                                                                Reject
                                                            </button>
                                                        </td>
                                                        <td>
                                                        <button  onClick={() => handleDeleteOffer(item.id)} style={{ backgroundColor:'black'}}>Delete</button>

                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="12" style={{ textAlign: 'center' }}>
                                                        No business data available
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Fullscreen Image Modal */}
                    {fullScreenImage && (
                        <div className="fullscreen-modal" onClick={handleCloseFullScreen}>
                            <div className="fullscreen-image-container">
                                <img
                                    src={fullScreenImage}
                                    alt="Full Screen"
                                    className="fullscreen-image"
                                />
                            </div>
                        </div>
                    )}
  </div>
)}

{/* //ACTIVE CONTENT 15 */}
{activeContent === 'content15' && (
                      <div>
                        <div>
                    <div className="content-14-container">
                        <div className="main-content3">
                            {loading ? (
                                <p>Loading...</p>
                            ) : (
                                <div
                                    className="table-responsive"
                                    style={{
                                        maxHeight: '35rem',
                                        overflowX: 'auto',
                                        fontSize: '12px',
                                    }}
                                >
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>Offer Id</th>
                                                <th>User Id</th>
                                                <th>Business Id</th>
                                                <th>Offer Title</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                                <th>Offer Status</th>
                                                <th>Approval Status</th>
                                                <th>Remarks</th>
                                                <th>Offer Type</th>
                                                <th>Remark</th>
                                                <th>Approve</th>
                                                <th>Reject</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {finalImageOffers.length > 0 ? (
                                                finalImageOffers.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.id}</td>
                                                        <td>{item.user_id}</td>
                                                        <td>{item.business_id}</td>
                                                        <td>{item.offer_title}</td>
                                                        <td>{item.start_date}</td>
                                                        <td>{item.end_date}</td>
                                                        <td>{item.start_time}</td>
                                                        <td>{item.end_time}</td>
                                                        <td>{item.offer_status}</td>
                                                        <td>{item.approval_status}</td>
                                                        <td>{item.remark}</td>
                                                        <td>{item.offer_type}</td>

                                                        {/* Small Image with Clickable Full-Screen Feature */}
                                                        <td>
                                                       
                                                            <img 
                                                                style={{
                                                                    width: '5rem',
                                                                    height: '5rem',
                                                                    cursor: 'pointer',
                                                                  
                                                                }}
                                                                src={item.offer_image}
                                                                alt="Offer Image"
                                                                onClick={() =>
                                                                    handleOpenFullScreen(
                                                                        item.offer_image
                                                                    )
                                                                }
                                                            />
                                                        </td>

                                                        {/* Approve / Reject Buttons */}
                                                        <td>
                                                            <button style={{backgroundColor:'green'}} onClick={() => handleApproveOffer(item.id)}>
                                                                Approve
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button style={{backgroundColor:'red'}} onClick={() => handleRejectOffer(item.id)}>
                                                                Reject
                                                            </button>
                                                        </td>
                                                        <td>
                                                        <button  onClick={() => handleDeleteOffer(item.id)} style={{ backgroundColor:'black'}}>Delete</button>

                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="12" style={{ textAlign: 'center' }}>
                                                        No business data available
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Fullscreen Image Modal */}
                    {fullScreenImage && (
                        <div className="fullscreen-modal" onClick={handleCloseFullScreen}>
                            <div className="fullscreen-image-container">
                                <img
                                    src={fullScreenImage}
                                    alt="Full Screen"
                                    className="fullscreen-image"
                                />
                            </div>
                        </div>
                    )}
                </div>
                        </div>
)}

{/* //contemt 16         */}
 {activeContent === 'content16' && (
                    <div>
                      <div>
                    <div className="content-14-container">
                        <div className="main-content3">
                            {loading ? (
                                <p>Loading...</p>
                            ) : (
                                <div
                                    className="table-responsive"
                                    style={{
                                        maxHeight: '35rem',
                                        overflowX: 'auto',
                                        fontSize: '12px',
                                    }}
                                >
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>Offer Id</th>
                                                <th>User Id</th>
                                                <th>Business Id</th>
                                                <th>Offer Title</th>
                                                <th>Description</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Start Time</th>
                                                <th>End Time</th>
                                                <th>Offer Status</th>
                                                <th>Approval Status</th>
                                                <th>Remarks</th>
                                                <th>Offer Type</th>
                                                <th>Remark</th>
                                                <th>Approve</th>
                                                <th>Reject</th>
                                                <th>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {finalDescriptionOffers.length > 0 ? (
                                                finalDescriptionOffers.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.id}</td>
                                                        <td>{item.user_id}</td>
                                                        <td>{item.business_id}</td>
                                                        <td>{item.offer_title}</td>
                                                        <td>{item.offer_description}</td>
                                                        <td>{item.start_date}</td>
                                                        <td>{item.end_date}</td>
                                                        <td>{item.start_time}</td>
                                                        <td>{item.end_time}</td>
                                                        <td>{item.offer_status}</td>
                                                        <td>{item.approval_status}</td>
                                                        <td>{item.remark}</td>
                                                        <td>{item.offer_type}</td>

                                                        {/* Small Image with Clickable Full-Screen Feature */}
                                                        <td>
                                                       
                                                            <img 
                                                                style={{
                                                                    width: '5rem',
                                                                    height: '5rem',
                                                                    cursor: 'pointer',
                                                                  
                                                                }}
                                                                src={item.offer_image}
                                                                alt="Offer Image"
                                                                onClick={() =>
                                                                    handleOpenFullScreen(
                                                                        item.offer_image
                                                                    )
                                                                }
                                                            />
                                                        </td>

                                                        {/* Approve / Reject Buttons */}
                                                        <td>
                                                            <button style={{backgroundColor:'green'}} onClick={() => handleApproveOffer(item.id)}>
                                                                Approve
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button style={{backgroundColor:'red'}} onClick={() => handleRejectOffer(item.id)}>
                                                                Reject
                                                            </button>
                                                        </td>
                                                        <td>
                                                        <button  onClick={() => handleDeleteOffer(item.id)} style={{ backgroundColor:'black'}}>Delete</button>

                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="12" style={{ textAlign: 'center' }}>
                                                        No business data available
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Fullscreen Image Modal */}
                    {fullScreenImage && (
                        <div className="fullscreen-modal" onClick={handleCloseFullScreen}>
                            <div className="fullscreen-image-container">
                                <img
                                    src={fullScreenImage}
                                    alt="Full Screen"
                                    className="fullscreen-image"
                                />
                            </div>
                        </div>
                    )}
                </div>
                      </div>
 )}

              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Pagination */}
      <div className="parent-pagination">
        <Pagination>{generatePaginationItems()}</Pagination>
      </div>
    </div>
  );
}

export default Dashboard;


