import React from 'react';
import "../styles/modal.scss"; // Ensure you have some basic styling for the modal

// Modal Component
const Modal = ({ isOpen, closeModal, children }) => {
  if (!isOpen) return null; // Don't render modal if it's not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-btn" onClick={closeModal}>X</button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
