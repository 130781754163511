import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Fetch image offers asynchronously
export const getImageOffers = createAsyncThunk(
  'getImageOffers',
  async () => {
    try {
      const response = await fetch('https://vendor.payzopartner.in/payzo-api/getAllImageOffers');
      if (!response.ok) {
        throw new Error("Error fetching Image Offers");
      } else {
        return response.json();
      }
    } catch (error) {
      console.log(error);
      throw error; // Throw the error so that rejected action is triggered
    }
  }
);

const ImageOffersSlice = createSlice({
  name: 'ImageOffers',
  initialState: {
    loading: false,
    error: null,
    ImageOffers: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getImageOffers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getImageOffers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.ImageOffers = action.payload; // Assuming payload contains the array of image offers
      })
      .addCase(getImageOffers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.ImageOffers = []; // Reset offers on failure
      });
  }
});

export default ImageOffersSlice.reducer;
