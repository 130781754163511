import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


export const getMobileData = createAsyncThunk(
    'mobileData',
    async ()=> {
        const response = await fetch('https://vendor.payzopartner.in/payzo-api/getMobilescreens', {
            method : 'GET',
            headers : {
                'content-type' : 'application/json'
            }
        })
        if(response.ok){
            return await response.json();
        }else{
            throw new Error("Http Error")
        }
    }
)


const getMobileDataSlice = createSlice({
    name : 'getMobileData',
    initialState : {
        MobileData : [],
        loading  : false,
    error : null        
    },
    reducers : {},
    extraReducers : (builder)=> {
        builder 
        .addCase(getMobileData.pending, (state)=> {
            state.loading = true;
            state.error = null;
        })
        .addCase(getMobileData.fulfilled, (state, action)=> {
            state.loading = false;
            state.MobileData = action.payload;
        })
        .addCase(getMobileData.rejected, (state, action)=> {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})


export default getMobileDataSlice.reducer;