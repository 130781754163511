import React, { useEffect, useState } from 'react';
import '../styles/fullscreen.scss'; // Import the necessary CSS for styling
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from './modal';

const FullScreenCard = ({ businessId }) => {
  const [isFullScreen, setIsFullScreen] = useState(false); // State to manage full-screen mode
  const [businessData, setBusinessData] = useState([]); // To store business details
  const [approvalStatus, setApprovalStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false); 
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state
  const [remarks, setRemarks] = useState(''); // State for remarks

  const openModal = () => setIsModalOpen(true); // Open the modal
  const closeModal = () => setIsModalOpen(false);
  // Loader state

  // Fetch business data when component loads or businessId changes
  useEffect(() => {
    const fetchBusinessByIDData = async () => {
      try {
        const response = await axios.get(`https://vendor.payzopartner.in/payzo-api/getBusinessById/${businessId}`);
        if (response.data.success) {
          setBusinessData(response.data.business); // Set the business data
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (businessId) {
      fetchBusinessByIDData();
    }
  }, [businessId]);

  // Update the business status when approval status or remarks change
  // useEffect(() => {
  //   if (approvalStatus && businessData) {
  //     const updateBusinessData = async () => {
  //       setIsLoading(true); // Start loading

  //       try {
  //         const updateresponse = await axios.post('https://vendor.payzopartner.in/payzo-api/updateBusiness', {
  //           business_id: businessId,
  //           business_type: businessData.business_type,
  //           approval_status: approvalStatus,
  //           remark: remarks,
  //           pincode: businessData.pincode,
  //           user_id: businessData.user_id,
  //           vendor_name: businessData.vendor_name,
  //           business_name: businessData.business_name,
  //           category_id: businessData.category_id,
  //           city_id: businessData.city_id,
  //           state_id: businessData.state_id,
  //           address: businessData.address,
  //           email: businessData.email,
  //           google_map_link: businessData.google_map_link,
  //           website_link: businessData.website_link,
  //           store_timing: businessData.store_timing,
  //           profile_image: businessData.profile_image,
  //           phone_number: businessData.phone_number,
  //           promo_code : businessData.promo_code,
  //           kyc_doc_image : businessData.kyc_doc_image,
  //           documentId : businessData.documentId,
  //           documentTitle : businessData.documentTitle

  //         });

  //         if (updateresponse.data.success) {
  //         } else {
  //           toast.error("Please try again later");
  //         }
  //       } catch (error) {
  //         console.log(error);
  //         toast.error("Error occurred while updating business");
  //       } finally {
  //         // Introduce a 10-second delay before stopping loader and refreshing
  //         setTimeout(() => {
  //           setIsLoading(false); // Stop loading
  //           // Refresh the page
  //         }, 3000); 
  //        // 10 seconds delay
  //       }
  //     };

  //     updateBusinessData();
  //   }
  // }, [approvalStatus, remarks, businessData, businessId]);

  const handleEyeIconClick = () => {
    setIsFullScreen(true); // Enable full-screen view when the eye icon is clicked
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false); // Close the full-screen view
  };

  // Handle review submission and change approval status to rejected
  const handleReviewSubmit = async (e) => {
    e.preventDefault();

    if (!remarks.trim()) {
      toast.error("Please provide a reason for rejection.");
      return;
    }

    setIsLoading(true); // Start loading when submitting

    try {
      // Set approval status to rejected
      setApprovalStatus("rejected");

      // Wait for a small delay for state to update
      await new Promise((resolve) => setTimeout(resolve, 200));

      // Close the modal after the review has been submitted
      setIsModalOpen(false);
      toast.success("Review Added successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error submitting review. Please try again.");
    } finally {
      setIsLoading(false); // Stop loading after submission
    }
  };



  const handleApprovaBusiness = async(e)=> {
    e.preventDefault();
    try {
      const updateresponse = await axios.post('https://vendor.payzopartner.in/payzo-api/updateBusiness', {
        business_id: businessId,
        business_type: businessData.business_type,
        approval_status: "approved",
        remark: remarks,
        pincode: businessData.pincode,
        vendor_id: businessData.vendor_id,
        vendor_name: businessData.vendor_name,
        business_name: businessData.business_name,
        category_id: businessData.category_id,
        city_id: businessData.city_id,
        state_id: businessData.state_id,
        address: businessData.address,
        email: businessData.email,
        google_map_link: businessData.google_map_link,
        website_link: businessData.website_link,
        store_timing: businessData.store_timing,
        profile_image: businessData.profile_image,
        phone_number: businessData.phone_number,
        promo_code : businessData.promo_code,
        kyc_doc_image : businessData.kyc_doc_image,
        documentId : businessData.documentId,
        documentTitle : businessData.documentTitle

      });
      if(updateresponse.data.success === true){
        toast.success("Business Approved Successfully");
      }else{
        toast.error("Business Approval Failed")
      }
    } catch (error) {
      console.log(error);
    }
  }


  const handleRejectBusiness = async(e)=> {
    e.preventDefault();
    try {
      const updateresponse = await axios.post('https://vendor.payzopartner.in/payzo-api/updateBusiness', {
        business_id: businessId,
        business_type: businessData.business_type,
        approval_status: "rejected",
        remark: remarks,
        pincode: businessData.pincode,
        vendor_id: businessData.vendor_id,
        vendor_name: businessData.vendor_name,
        business_name: businessData.business_name,
        category_id: businessData.category_id,
        city_id: businessData.city_id,
        state_id: businessData.state_id,
        address: businessData.address,
        email: businessData.email,
        google_map_link: businessData.google_map_link,
        website_link: businessData.website_link,
        store_timing: businessData.store_timing,
        profile_image: businessData.profile_image,
        phone_number: businessData.phone_number,
        promo_code : businessData.promo_code,
        kyc_doc_image : businessData.kyc_doc_image,
        documentId : businessData.documentId,
        documentTitle : businessData.documentTitle

      });
      if(updateresponse.data.success === true){
        toast.success("Business Rejected Successfully");
      }else{
        toast.error("Please try again Admin")
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      {/* Eye Icon to open the full-screen card */}
      <button onClick={handleEyeIconClick} className="eye-icon">
        👁️
      </button>

      {/* Full-screen card that appears when isFullScreen is true */}
      {isFullScreen && businessData && (
        <div className="fullscreen-card">
          <button className="close-btn" onClick={handleCloseFullScreen}>X</button>
          <div className='content'>
            <img className='profile-viewer' src={businessData.kyc_doc_image} alt='' />
            <img className='profile-viewer' src={businessData.profile_image} alt='' />
            </div>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', gap:'1rem', padding:'1rem'}}>
               <button onClick={handleApprovaBusiness}>Approve</button>
               <button onClick={openModal}>Reject</button>

      <Modal isOpen={isModalOpen} closeModal={closeModal}>
        <h6 style={{color:'red'}}>Please mention Why you rejecting Business ?</h6>
        <textarea
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          placeholder="Enter your rejection reason"
        />
        <button onClick={handleRejectBusiness}>Submit</button>
        <button onClick={closeModal}>Cancel</button>
      </Modal>
              </div>
        </div>
      )}

      {/* Loader - Show this when `isLoading` is true */}
      {isLoading && (
        <div className="loader">
          <div className="spinner"></div>
          <p>Updating...</p>
        </div>
      )}
    </div>
  );
};

export default FullScreenCard;
