import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";


export const getAllCities = createAsyncThunk(
  'cities/getAllCities', // Action name
  async (selectedState) => {
    try {
      const cityResponse = await axios.post(
        'https://vendor.payzopartner.in/payzo-api/getCities',
        {
          state_id: selectedState, 
        },
        {
          headers: {
            'Content-Type': 'application/json', 
          },
        }
      );

      if (cityResponse.data.success === true) {
        toast.success("Cities fetched successfully");
        return cityResponse.data.data;
      } else {
        // toast.error("Failed to fetch cities");
        return [];
      }
    } catch (error) {
      toast.error("Something went wrong fetching cities!");
      console.error(error); // Log the error for debugging purposes
      return []; // Return an empty array in case of error
    }
  }
);

// Define the slice for cities
const getCitiesSlice = createSlice({
  name: 'getAllCities',
  initialState: {
    getAllCitiesData: [], // Data to store cities
    loading: false,        // Track loading state
    error: null,           // Track any error state
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle the loading state when the async thunk is pending
      .addCase(getAllCities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // Handle the successful response
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllCitiesData = action.payload; // Store the data fetched from the API
      })
      // Handle any errors that occurred during the fetch
      .addCase(getAllCities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Store the error message
        toast.error(state.error); // Show the error toast
      });
  },
});

// Export the reducer to be used in the store
export default getCitiesSlice.reducer;
